import React from 'react';
import { Classable, HasChildren } from '@shapeable/types';
import styled, { css } from 'styled-components';
import { breakpoints, theme } from '@shapeable/theme';
import { Topic } from '@shapeable/gesda-types';
import { Content, ContentNode, ImageAsset, MarkdownContent, SectionTitle } from '@shapeable/ui';

// -------- Types -------->

export type TopicAnticipatoryImpactLayoutProps = Classable & HasChildren & {
  entity?: Topic;
  title?: string;
}

export const TopicAnticipatoryImpactLayoutDefaultProps: Omit<TopicAnticipatoryImpactLayoutProps, 'entity'> = {
  title: 'Anticipatory Impact:',
};

// -------- Child Component Props -------->

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.UNIT(8)};
  `,
  tablet: css`
    flex-direction: row;
    align-items: flex-start;
  `,
});

const BodyStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
  `,
});

const HeadingStyles = breakpoints({
  base: css`
    font-weight: 400;
  `,
});

const ContentStyles = breakpoints({
  base: css`
    font-weight: 300;
  `,
});

const ImageStyles = breakpoints({
  base: css`
    align-self: center;
    width: 80%;
    height: auto;
    flex-shrink: 0;
  `,
  tablet: css`
    width: 40%;
  `,
});




// -------- Components -------->

const My = {
  Container: styled.div<any>`${ContainerStyles}`,
    Body: styled.div`${BodyStyles}`,
    Heading: styled(SectionTitle)`${HeadingStyles}`,
    Content: styled(ContentNode)`${ContentStyles}`,
    Image: styled(ImageAsset)`${ImageStyles}`,
};

export const TopicAnticipatoryImpactLayout: React.FC<TopicAnticipatoryImpactLayoutProps> = (props) => {
  const { className, title, entity } = props;
  const { anticipatoryImpact, anticipatoryImpactImage } = entity;

  const hasText = anticipatoryImpact && !!anticipatoryImpact.text;
  const hasImage = anticipatoryImpactImage && anticipatoryImpactImage.image && anticipatoryImpactImage.image.url;

  return (
    <My.Container className={className}>
      <My.Body>
        <My.Heading>{title}</My.Heading>
        {
          hasText && <My.Content entity={anticipatoryImpact} />
        }
      </My.Body>
      {
        hasImage && <My.Image entity={anticipatoryImpactImage} />
      }
    </My.Container>
  )
};

TopicAnticipatoryImpactLayout.defaultProps = TopicAnticipatoryImpactLayoutDefaultProps;