import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames } from '@shapeable/utils';
import { Topic } from '@shapeable/gesda-types';
import { ContentTabs, ContentTabSpec, useView } from '@shapeable/ui';
import { AiUpperCaseIconGlyph } from '@shapeable/icons';
import { compact } from 'lodash';
import { TopicView, TrendView } from '../../types';
import { sortBy } from 'lodash';
import { useCurrentEditionMembers } from '../../hooks/use-current-edition-members';
const cls = classNames('topic-tabs-layout');

// -------- Types -------->

// -------- Props -------->

export type TopicTabsLayoutProps = Classable & HasChildren & { 
  entity?: Topic;
};

export const TopicTabsLayoutDefaultProps: Omit<TopicTabsLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

};

// -------- Styles -------->


const TabsStyles = breakpoints({
  base: css`
  `,
});

// -------- Components -------->

const My = {
  Tabs: styled(ContentTabs)<ContainerProps>`${TabsStyles}`,

};

export const TopicTabsLayout: Shapeable.FC<TopicTabsLayoutProps> = (props) => {
  const { className, children, entity } = props;

  const { color, connectedPages = [], committeeChairs = [], committeeMembers = [],  citations = [], feedEntries = [], trend } = entity;

  const resources = sortBy([...citations, ...feedEntries], 'date');

  const allconnections = [...connectedPages, trend];
  
  const currentCommitteeMembers = useCurrentEditionMembers(committeeMembers);

  const hasContributors = !!currentCommitteeMembers.length;
  const hasConnections = !!allconnections.length;
  const hasResources = !!resources.length;

  const tabs: ContentTabSpec<TopicView>[] = compact([
    {
      id: 'radar-ai',
      icon: <AiUpperCaseIconGlyph />
    },
    {
      id: 'overview',
      label: 'Overview',
    },
    hasConnections &&
    {
      id: 'connections',
      label: 'Connections',
      count: allconnections.length,
    },
    hasResources &&
    {
      id: 'resources',
      label: 'Resources',
      count: resources.length,
    },
    hasContributors &&
    {
      id: 'contributors',
      label: 'Committee',
      count: currentCommitteeMembers.length,
    }
  ]);

  const { activeView, showView, setActiveView } = useView<TopicView>(`topic-${entity.slug}`, 'overview');
  
  return (
    <My.Tabs onTabClick={setActiveView} activeTab={activeView} items={tabs} colors={{ border: color?.value, hover: { text: color?.value } }} />
  )
};

TopicTabsLayout.defaultProps = TopicTabsLayoutDefaultProps;
TopicTabsLayout.cls = cls;